import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Testimonials from "../components/testimonials"
import YoutubeIframe from "../components/youtube-iframe"

export default ({data}) => (
  <Layout title="Reviews | Crampete">
  <Jumbotron className="bg-lightblue">
    <div className="container">
      <div style={{maxWidth:"600px",textAlign:"center",margin:"auto"}}>
        <h2>A community in the making!</h2>
        <p className="mt-4">
          Here's what our students have to say about being a part of the Crampete family and learning skills that empower them! A community in the making- sharing skills, experience and knowledge!
        </p>
    </div>
    </div>
  </Jumbotron>
    <div className="container">
      <div className="row">
        <div className="col-md-6 mb-4">
          <div className="iframe-wrapper">
            <iframe title="Crampete Reviews" src="https://player.vimeo.com/video/478364652?color=39c&title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="iframe-wrapper">
            <iframe title="Crampete Reviews" src="https://player.vimeo.com/video/478364994?color=39c&title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="iframe-wrapper">
            <iframe title="Crampete Reviews" src="https://player.vimeo.com/video/478365206?color=39c&title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="iframe-wrapper">
            <YoutubeIframe videoId="YocFo2XLJXY" autoplay="1" />
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <Testimonials />
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
